import { Injectable } from '@angular/core';
import { GlpBaseFacetStateService } from '../../services/glp-base-facet-state.service';
import {
    Entity as InternalEntity,
    TaskInstance
}
    from '../../common/types';
import { DataManagerService } from '../../services/data-manager.service';
import { Entity as BreezeEntity } from 'breeze-client';

@Injectable()
export class TaskInstanceStateService extends GlpBaseFacetStateService<TaskInstance> {

    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(schedule: InternalEntity<TaskInstance>): BreezeEntity[] {
        const changes: any = [];
        return changes;
    }
    
    discardChanges(schedule: InternalEntity<TaskInstance>): void {
        // TODO: implement facet-level discard operation after release of GLP
        throw new Error('Method not implemented.');
    }

    getDeleteNavPropertyChanges(entity: InternalEntity<TaskInstance>): BreezeEntity[] {
        const changes: any = [];       
        return changes;
    }    
}
