import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Input } from "@angular/core";
import { JobPharmaDetailService } from "../services/job-pharma-detail.service";


export interface BulkUpdateInputRequest {
    jobKey: number,
    workflowTaskKey: number,
    taskName?: string,
    cohortKey?: number,
    inputKey: number,
    value: string,
}

@Injectable()
export class JobPharmaTasksInputsTableService {

    constructor(
        private httpClient: HttpClient,
        private jobPharmaDetailService: JobPharmaDetailService
    ) {}

    // TODO: Separate data access logic
    async bulkUpdateInput(request: BulkUpdateInputRequest): Promise<void> {
        const httpOptions = { headers: new HttpHeaders({'x-single-request': '1'})};
        await this.httpClient.post('/api/bulkdata/input', request, httpOptions).toPromise();
        this.jobPharmaDetailService.tabRefresh('tasks', 'inputs');
    }
}