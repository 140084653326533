import { Injectable } from '@angular/core';
import { GlpBaseFacetStateService } from '../../services/glp-base-facet-state.service';
import {
    Entity as InternalEntity,
    Input,
    Output,
    WorkflowTask
}
    from '../../common/types';
import { DataManagerService } from '../../services/data-manager.service';
import { Entity as BreezeEntity } from 'breeze-client';

@Injectable()
export class TaskStateService extends GlpBaseFacetStateService<WorkflowTask> {

    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(task: InternalEntity<WorkflowTask>): BreezeEntity[] {
        const changes: any = [];
        changes.push(...this.getChangesToInputs(task));
        changes.push(...this.getChangesToOutputs(task));
        return changes;
    }

    public getChangesToInputs(
        workflowTask: WorkflowTask,
    ): InternalEntity<Input>[] {      
        const InputsIdForTask = workflowTask.Input.map(input => input.C_Input_key);

        const allChangedInputs = (this.dataManagerService
            .getManager()
            .getChanges("Input") ?? []) as InternalEntity<Input>[];
       
        const changedInputsForTask = allChangedInputs
            .filter((input) =>
                InputsIdForTask.some(
                    (inputId) =>
                        inputId === input.C_Input_key,
                ),
        );     
      
        return changedInputsForTask;
    }

    public getChangesToOutputs(
        workflowTask: WorkflowTask,
    ): InternalEntity<Output>[] {
        const OutputsIdForTask = workflowTask.Output.map(output => output.C_Output_key);

        const allChangedOutputs = (this.dataManagerService
            .getManager()
            .getChanges("Output") ?? []) as InternalEntity<Output>[];
        
        const changedOutputsForTask = allChangedOutputs
            .filter((input) =>
                OutputsIdForTask.some(
                    (inputId) =>
                        inputId === input.C_Output_key,
                ),
            );        
        return changedOutputsForTask;
    }
        
    discardChanges(task: InternalEntity<WorkflowTask>): void {
        // TODO: implement facet-level discard operation after release of GLP
        throw new Error('Method not implemented.');
    }

    getDeleteNavPropertyChanges(entity: InternalEntity<WorkflowTask>): BreezeEntity[] {
        const changes: any = [];
        changes.push(...this.getChangesToDeletedInputs(entity))
        changes.push(...this.getChangesToDeletedOutputs(entity))
        return changes;
    }       

    private getChangesToDeletedInputs(workflowTask: WorkflowTask): InternalEntity<Input>[] {        
        return this.dataManagerService
            .getChangesToRelatedDeletedEntityByKey<Input>('Input',
                'C_WorkflowTask_key', workflowTask.C_WorkflowTask_key);
    }

    private getChangesToDeletedOutputs(workflowTask: WorkflowTask): InternalEntity<Output>[] {
        return this.dataManagerService
            .getChangesToRelatedDeletedEntityByKey<Output>('Output',
                'C_WorkflowTask_key', workflowTask.C_WorkflowTask_key);
    }
}
