import { Pipe, PipeTransform } from '@angular/core';
import { SampleGroupExtended } from '../pharma/services';
import { hasAssociatedSamples } from '../utils/has-associated-samples';


@Pipe({
    name: 'hasAssociatedSamples',
    pure: false
})
export class HasAssociatedSamplesPipe implements PipeTransform {
    transform(sampleGroup: SampleGroupExtended): boolean {
        return hasAssociatedSamples(sampleGroup);
    }
}
