import { ColumnSelect, ColumnSelectLabel } from "@common/facet"

export interface VisibleColumns { [column: string]: boolean }

export abstract class BaseTableColumnsHelper {
    constructor(visibleColumns: VisibleColumns) {
        this.visibleColumns = visibleColumns;
    }

    private columnSelect: ColumnSelect = { model: [], labels: [] };
    private visibleColumns: VisibleColumns;

    public create(activeFields: string[] = []): {
        columnSelect: ColumnSelect,
        visibleColumns: VisibleColumns,
        updateVisibleColumns: () => void
    } {
        this.setupColumnSelectLabels(new Set(activeFields));
        this.cleanupColumnSelectLabels();
        this.cleanupColumnSelectModels();

        return {
            columnSelect: this.columnSelect,
            visibleColumns: this.visibleColumns,
            updateVisibleColumns: this.updateVisibleColumns.bind(this)
        };
    }

    private updateVisibleColumns() {
        const selected: {[key: string]: boolean} = {};
        this.columnSelect.model.forEach((key) => selected[key] = true);
        this.columnSelect.labels.forEach(({key}) => this.visibleColumns[key] = (selected[key] === true));
    }

    protected abstract setupColumnSelectLabels(activeFields: Set<string>): void;
    protected set columnSelectLabels(columnSelectLabels: ColumnSelectLabel[]) {
        this.columnSelect.labels = columnSelectLabels;
    }

    private cleanupColumnSelectLabels() {
        this.columnSelect.labels = this.columnSelect.labels
            .filter((label: ColumnSelectLabel) => label !== null);
    }

    private cleanupColumnSelectModels() {
        this.columnSelect.model = this.columnSelect.labels
            .filter((item) => this.visibleColumns[item.key])
            .map((item) => item.key);
    }
}