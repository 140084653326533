import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    ViewChildren,
    Injector,
    SimpleChanges
} from '@angular/core';
import { IBeforeSaveInterceptor, SaveCancellationToken, SaveChangesService } from '@services/save-changes.service';
import { FacetView, IFacet } from '@common/facet';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { LoggingService } from '@services/logging.service';
import { Base, CanUnsubscribeMixin, applyMixins, mixinUnsubscribe } from '@common/mixins';
import { JobPharmaSampleGroupsTableService } from './job-pharma-samples-groups-table.service';
import { JobPharmaDetailService } from '../../services';
import { VisibleColumns } from '@common/util/table-columns/base-table-columns-helper';
import { SampleGroupTableColumnsHelper } from './sample-group-table-columns-helpers';

const Mixins: CanUnsubscribeMixin & typeof Base = applyMixins(mixinUnsubscribe);

@Component({
    selector: 'job-pharma-samples-groups-table',
    templateUrl: './job-pharma-samples-groups-table.component.html',
    providers: [
        JobPharmaSampleGroupsTableService,
        SampleGroupTableColumnsHelper
    ]
})
export class JobPharmaSamplesGroupsTableComponent extends Mixins implements OnChanges, OnDestroy, OnInit, IBeforeSaveInterceptor {
    readonly COMPONENT_LOG_TAG = 'job-pharma-samples-groups-table';
    readonly tabset = 'samples';
    readonly tab = 'groups';

    @Input() job: any;
    @Input() readonly: boolean;

    @Input() facet: IFacet;
    @Input() facetView: FacetView;

    @Input() activeFields: string[] = [];
    @Input() requiredFields: string[] = [];
    
    @ViewChildren('dateControl') dateControls: NgModel[];
    @ViewChildren('harvestDateControl') harvestDateControls: NgModel[];
    @ViewChildren('expirationDateControl') expirationDateControls: NgModel[];

    visibleColumns: VisibleColumns;
    isSampleGroupsEditableFlag: boolean;

    constructor(
        injector: Injector,
        public jobPharmaSampleGroupsTableService: JobPharmaSampleGroupsTableService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private sampleGroupTableColumnsHelper: SampleGroupTableColumnsHelper,
        private saveChangesService: SaveChangesService,
        private loggingService: LoggingService
    ) {
        super(injector);
    }

    async ngOnInit() {
        try {
            this.jobPharmaSampleGroupsTableService.setLoading(true);
            this.jobPharmaSampleGroupsTableService.register(this);
            this.saveChangesService.registerBeforeSaveInterceptor(this);
            this.isSampleGroupsEditableFlag = this.jobPharmaDetailService.isSampleGroupsEditableFlag();
            this.initChangeDetection();
            this.initTabActions();
            this.initColumnSelect();
            await this.jobPharmaSampleGroupsTableService.loadVocabularies();
            await this.jobPharmaSampleGroupsTableService.loadSampleGroups(this.job.C_Job_key);
        } catch(err) {
            this.loggingService.logError("Erorr loading sample groups table", err, this.COMPONENT_LOG_TAG, false);
        } finally {
            this.jobPharmaSampleGroupsTableService.setLoading(false);
        }
    }
    
    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        if (changes.job && !changes.job.firstChange) {
            this.jobPharmaSampleGroupsTableService.loadSampleGroups(this.job.C_Job_key);
            this.jobPharmaSampleGroupsTableService.clearSelected();
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.saveChangesService.unregisterBeforeSaveInterceptor(this);
        this.jobPharmaSampleGroupsTableService.clearSelected();
    }

    initChangeDetection() {
        this.subscription = this.jobPharmaDetailService.jobTasksChanged$.subscribe(() => {
            this.jobPharmaSampleGroupsTableService.loadSampleGroups(this.job.C_Job_key);
            this.jobPharmaSampleGroupsTableService.clearSelected();
        });

        this.subscription = this.saveChangesService.saveSuccessful$.subscribe(() => {
            /**
             * Sample Group metadata will need to be refreshed if changes are made via Breeze.
             */
            this.jobPharmaSampleGroupsTableService.refreshSampleGroupsMetadata();
        });
    }

    initTabActions() {
        this.subscription = this.jobPharmaDetailService.tabRefresh$.subscribe((event) => {
            if (event.tabset === 'samples' && event.tab === 'groups') {
                this.jobPharmaSampleGroupsTableService.loadSampleGroups(this.job.C_Job_key);
                this.jobPharmaSampleGroupsTableService.clearSelected();
            }
        });
    }

    initColumnSelect() {
        const {
            columnSelect,
            visibleColumns,
            updateVisibleColumns
        } = this.sampleGroupTableColumnsHelper.create(this.activeFields);
        this.visibleColumns = visibleColumns;
        
        this.subscription = this.jobPharmaDetailService.registerColumnSelect(
            this.tabset, 
            this.tab, 
            columnSelect,
            () => { updateVisibleColumns(); }
        );

        updateVisibleColumns();
    }

    async beforeSave(token: SaveCancellationToken): Promise<void> {
        if (!this.isSampleGroupsEditableFlag) {
            return;
        }
        
        try {
            await this.jobPharmaSampleGroupsTableService.handlePendingSampleGroupChanges(token);
        } finally {
            this.jobPharmaSampleGroupsTableService.resetSampleGroupChanges();
        }
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}
