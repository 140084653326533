import { NgxPaginationModule } from 'ngx-pagination';
import { TasksEndState, TaskFilterPipe } from './pipes';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';

import { TaskVocabService } from './task-vocab.service';
import { TaskSelectComponent } from './task-select.component';
import { TaskService } from './task.service';
import { EditTasksComponent } from './edit-tasks.component';
import { TaskCompleteButtonComponent } from './task-complete-button.component';
import {
    TaskOutputSelectModalComponent,
    TaskOutputSelectComponent,
    ViewTaskOutputSelectComponentService
} from './outputs';
import { TaskValidationService } from './task-validation.service';
import { TaskStateService } from './services/task-state.service';
import { TaskSaveService } from './services/task-save.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxPaginationModule,
        ClimbCommonModule,
    ],
    declarations: [
        TaskSelectComponent,
        EditTasksComponent,
        TaskCompleteButtonComponent,
        TaskOutputSelectModalComponent,
        TaskOutputSelectComponent,
        TaskFilterPipe,
        TasksEndState
    ],
    exports: [
        TaskSelectComponent,
        EditTasksComponent,
        TaskCompleteButtonComponent,
        TaskOutputSelectModalComponent,
        TaskOutputSelectComponent,
        TaskFilterPipe,
        TasksEndState
    ],
    providers: [
        TaskValidationService,
        TaskSaveService,
        TaskStateService
    ]

})
export class TasksSharedModule {
    static forRoot(): ModuleWithProviders<TasksSharedModule> {
        return {
          ngModule: TasksSharedModule,
          providers: [
            TaskService,
            TaskVocabService,
            ViewTaskOutputSelectComponentService
          ]
        };
    }
}
