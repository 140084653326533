import { SaveChangesService } from '../../services/save-changes.service';
import { ScheduleService } from '../schedule.service';
import {
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../resources';
import { randomId } from '../../common/util';
import { ScheduleDetailComponent } from '../schedule-detail.component';
import { LoggingService } from '@services/logging.service'; 

@Component({
    selector: 'schedule-edit-modal',
    templateUrl: './schedule-edit-modal.component.html',
})
export class ScheduleEditModalComponent implements OnInit {    
    @ViewChild('scheduleDetail') scheduleDetail: ScheduleDetailComponent;

    @Input() appointment: any;
    @Input() facet: any;

    domIdAddition = '';
    // Vocabularies
    resources: any[];

    saving = false;

    readonly LOG_TAG = 'ScheduleEditModal';
    
    constructor(        
        private activeModal: NgbActiveModal,
        private saveChangesService: SaveChangesService,
        private resourceService: ResourceService,
        private scheduleService: ScheduleService,
        private loggingService: LoggingService,
    ) {
        //
    }

    async ngOnInit(): Promise<void> {    
        this.domIdAddition = randomId() + '_sem';
        await this.getCVs();
    }

    private async getCVs(): Promise<void> {
        const data = await this.resourceService.getAllResources();
        this.resources = data;
    }

    onCancel(): void {
        this.scheduleService.cancelTaskInstance(this.appointment.taskInstance);
        this.activeModal.dismiss();
    }

    onSubmit(): void {
        const errMessage = this.scheduleDetail.validate();
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        // Give UI events a chance to finish first
        setTimeout(() => {
            if (this.saveChangesService.hasChanges) {
                this.saving = true;
                this.scheduleService.calculateDueDates(this.appointment.taskInstance).then(() => {
                    this.saving = false;
                    this.activeModal.close();
                }).catch((err) => {
                    this.saving = false;
                    throw err;
                });
            } else {
                this.activeModal.close();
            }
        });
    }

    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }

    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }                                                    
}
