import { WorkflowService } from '../workflow/services/workflow.service';
import {
    Component,
    Input,
    OnInit,
    ViewChildren
} from '@angular/core';
import { map } from 'rxjs/operators';
import { TaskType } from '../tasks/models';
import { WorkflowLogicService } from '../workflow/services/workflow-logic.service';
import { WorkflowVocabService } from '../workflow/services/workflow-vocab.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'schedule-detail',
    templateUrl: './schedule-detail.component.html'
})
export class ScheduleDetailComponent implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() facet: any;
    @Input() task: any;
    @Input() taskType: TaskType;

    // CVs
    taskStatuses: any[] = [];
    taskTypes: any[] = [];

    // export enum to template
    TaskType = TaskType;

    loading = false;

    readonly COMPONENT_LOG_TAG = 'schedule-detail';


    constructor(
        private workflowService: WorkflowService,
        private workflowLogicService: WorkflowLogicService,
        private workflowVocabService: WorkflowVocabService
    ) { }

    // lifecycle
    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.loading = true;
        return this.getCVs().then(() => {
            return this.loadTaskData();
        }).then(() => {
            this.loading = false;
        }).catch((err) => {
            this.loading = false;
            throw err;
        });
    }

    getCVs(): Promise<any> {
        const p1 = this.workflowVocabService.taskStatuses$.pipe(map((data) => {
            this.taskStatuses = data;
        })).toPromise();

        const p2 = this.workflowVocabService.taskTypes$.pipe(map((data) => {
            this.taskTypes = data;
        })).toPromise();

        return Promise.all([p1, p2]);
    }

    loadTaskData(): Promise<any> {
        if (!this.task) {
            return Promise.resolve();
        }

        return this.workflowService.ensureTaskRelationshipsExpanded([this.task]);
    }

    // Status
    taskStatusChanged(): Promise<any> {
        this.workflowLogicService.taskStatusChanged(this.task);

        return Promise.resolve();
    }

    // <select> formatters
    taskStatusKeyFormatter = (value: any) => {
        return value.C_TaskStatus_key;
    }
    taskStatusFormatter = (value: any) => {
        return value.TaskStatus;
    }

    isReadOnly() {
        return this.task.IsLocked || this.facet.Privilege === "ReadOnly";
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }

    completedDateChanged(date?: Date) {
        this.workflowLogicService.taskCompletedDataChanged(this.task, date);
    }
}
