import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    Output,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet';

import {
    JobPharmaDetailService,
} from '../../services/job-pharma-detail.service';
import { Entity, Job, JobMaterial } from '@common/types';
import { JobPharmaCoreService } from '../../services/job-pharma-core.service';
import { DEFAULT_VISIBLE_COLUMNS } from './constants/table-column-options';
import { ISelectable } from '@common/types/utils/selectable.interface';
import { LoggingService } from '@services/logging.service';
import { VocabularyService } from 'src/app/vocabularies/vocabulary.service';
import { SaveChangesService } from '@services/save-changes.service';

type JobMaterialExtended = JobMaterial & ISelectable;

@Component({
    selector: 'job-pharma-samples-individual-table',
    templateUrl: './job-pharma-samples-individual-table.component.html',
})
export class JobPharmaSamplesIndividualTableComponent implements OnChanges, OnDestroy, OnInit {
    readonly tabset = 'samples';
    readonly tab = 'individual';
    readonly COMPONENT_LOG_TAG = 'job-pharma-samples-individual-table';

    private dragId: number = null;

    @Input() readonly: boolean;
    @Input() job: Entity<Job>;
    @Input() activeFields: string[] = [];
    @Output() selectedRowsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

    loading = false;
    loadingMessage = "Loading";
    page = 1;
    sampleJobMaterials: JobMaterialExtended[] = [];
    selectedRows: JobMaterialExtended[];
    sampleCount: number;
    columnSelect: ColumnSelect = {
        model: [],
        labels: [],
    };
    visibleColumns: { [key: string]: boolean } = {...DEFAULT_VISIBLE_COLUMNS};
    allRowsSelected = false;
    subs = new Subscription();
    shouldRefreshData: boolean;

    constructor(
        private jobPharmaDetailService: JobPharmaDetailService,
        private jobPharmaCoreService: JobPharmaCoreService,
        private loggingService: LoggingService,
        private vocabularyService: VocabularyService,
        private saveChangesService: SaveChangesService
    ) { }

    async ngOnInit() {
        this.initColumnSelect();
        this.initTabActions();
        this.initChangeDetection();
        await this.initSampleCVs();
        await this.initSampleData();
        this.clearSampleSelections();

    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.job && !changes.job.firstChange) {
            await this.initSampleData();
            this.clearSampleSelections();
        }
    }

    ngOnDestroy() {
        this.clearSampleSelections();
        this.subs.unsubscribe();
    }

    /**
     * Watch for external changes
     */
    initChangeDetection() {
        this.subs.add(this.jobPharmaDetailService.jobMaterialsChanged$.subscribe(async () => {
            await this.initSampleData();
            this.clearSampleSelections();
        }));
        this.subs.add(this.saveChangesService.saveSuccessful$.subscribe(async () => {
            if (this.shouldRefreshData){
                await this.initSampleData();
                this.shouldRefreshData = false;
            }
        }))
    }
    // TODO: Move this vocab data access somewhere else
    initSampleCVs() {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
            this.vocabularyService.ensureCVLoaded('cv_SampleTypes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleStatuses'),
            this.vocabularyService.ensureCVLoaded('cv_PreservationMethods'),
            this.vocabularyService.ensureCVLoaded('cv_ContainerTypes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleSubtypes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleProcessingMethods'),
            this.vocabularyService.ensureCVLoaded('cv_SampleAnalysisMethods'),
        ]);
    }

    initColumnSelect(){
        this.columnSelect.labels = [
            new ColumnSelectLabel('name', 'Name'),
            new ColumnSelectLabel('type', 'Type'),
            this.activeFields.includes("TimePoint") ? new ColumnSelectLabel('timePoint', 'Time Point') : null,
            new ColumnSelectLabel('status', 'Status'),
            this.activeFields.includes("DateHarvest") ? new ColumnSelectLabel('harvestDate', 'Harvest Date') : null,
            this.activeFields.includes("DateExpiration") ? new ColumnSelectLabel('expirationDate', 'Expiration Date') : null,
            this.activeFields.includes("C_PreservationMethod_key") ? new ColumnSelectLabel('preservation', 'Preservation') : null,
            this.activeFields.includes("Material.C_ContainerType_key") ? new ColumnSelectLabel('container', 'Container') : null,
            this.activeFields.includes("Material.MaterialSourceMaterial") ? new ColumnSelectLabel('source', 'Source') : null,
            this.activeFields.includes("Location") ? new ColumnSelectLabel('location', 'Location') : null,
            this.activeFields.includes("C_SampleSubtype_key") ? new ColumnSelectLabel('subtype', 'Subtype') : null,
            this.activeFields.includes("C_SampleProcessingMethod_key") ? new ColumnSelectLabel('processing', 'Processing') : null,
            this.activeFields.includes("SendTo") ? new ColumnSelectLabel('sendTo', 'Send To') : null,
            this.activeFields.includes("C_SampleAnalysisMethod_key") ? new ColumnSelectLabel('analysis', 'Analysis') : null,
            this.activeFields.includes("SpecialInstructions") ? new ColumnSelectLabel('specialInstructions', 'Special Instructions') : null
        ];

        this.columnSelect.labels = this.columnSelect.labels
            .filter((label: ColumnSelectLabel) => label !== null);

        this.columnSelect.model = this.columnSelect.labels
            .filter((item) => this.visibleColumns[item.key])
            .map((item) => item.key);

        this.subs.add(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, 
                this.tab, 
                this.columnSelect,
                () => { this.updateVisibleColumns(); }
            )
        );

        this.updateVisibleColumns();
    }

    updateVisibleColumns() {
        // Make a lookup table
        const selected: {[key: string]: boolean} = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visibleColumns[key] = (selected[key] === true);
        });
    }

    /**
     * Watch for event between the tabs
     */
    initTabActions() {
        // Listen for calls to refresh view
        this.subs.add(
            this.jobPharmaDetailService.tabRefresh$.subscribe(async (event) => {
                if (event.tabset === 'samples' && event.tab === 'individual') {
                    await this.initSampleData();
                    this.clearSampleSelections();
                }
            })
        );
    }

    private async initSampleData(): Promise<void> {
        this.loading = true;
        try {
            this.sampleCount = await this.jobPharmaCoreService.getSampleCount(this.job.C_Job_key);
            this.sampleJobMaterials = await this.jobPharmaCoreService.getSampleJobMaterials(this.job.C_Job_key, this.page);
        } catch (err) {
            this.loggingService.logError("Error initializing sample data", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false;
        }
    }

    clearSampleSelections() {
        if (!this.sampleJobMaterials.length){
            return;
        }
        this.sampleJobMaterials.forEach((jm) => { jm.isSelected = false; });
        this.allRowsSelected = false;        
    }

    allSelectedChanged() {
        this.sampleJobMaterials.forEach((jm) => { jm.isSelected = this.allRowsSelected; });
        this.selectionChanged();
    }

    isSelectedChanged() {
        this.allRowsSelected = this.sampleJobMaterials.every((jm) => jm.isSelected);
        this.selectionChanged();
    }

    selectionChanged() {
        this.selectedRows = this.getSelectedRows();
        this.selectedRowsChange.emit(this.selectedRows);
    }

    getSelectedRows(): any[] {
        return this.sampleJobMaterials.filter((jm) => jm.isSelected);
    }

    async onDropSamples(): Promise<void> {
        this.loading = true;
        try {
            await this.jobPharmaCoreService.onDropSamples(this.job);
            this.sampleCount = await this.jobPharmaCoreService.getSampleCount(this.job.C_Job_key);
        } catch (err) {
            this.loggingService.logError("Error dropping sample", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false;
        }
    }

    async onPasteSamples(): Promise<void> {
        this.loading = true;
        try {
            await this.jobPharmaCoreService.onPasteSamples(this.job);
            this.sampleCount = await this.jobPharmaCoreService.getSampleCount(this.job.C_Job_key);
        } catch (err) {
            this.loggingService.logError("Error pasting sample", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false
        }
        
    }

    async removeSampleJobMaterial(jobMaterial: JobMaterial): Promise<void> {
        this.loading = true;
        try {
            await this.jobPharmaCoreService.removeSampleJobMaterial(jobMaterial);
            this.shouldRefreshData = true;
        } catch (err) { 
            this.loggingService.logError("Error removing sample", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false;
        }
    }

    dragStart() {
        const selected = this.sampleJobMaterials
            .filter((jm) => jm.isSelected)
            .map((jm) => jm.Material.Sample);

        this.dragId = this.jobPharmaDetailService.startDrag('Sample', selected);
    }

    dragStop() {
        setTimeout(() => {
            this.jobPharmaDetailService.stopDrag(this.dragId);
        }, 500);
    }

    async changePage(newPage: number) {
        this.loading = true;
        try {
            this.sampleJobMaterials = await this.jobPharmaCoreService.getSampleJobMaterials(this.job.C_Job_key, newPage);
            this.page = newPage;
        } catch (err) {
            this.loggingService.logError("Error changing pages", err, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false;
        }
        
    }
}
