import { BehaviorSubject, Observable } from 'rxjs';
import { Directive } from '@angular/core';
import { Base, BaseConstructor, Constructor } from './mixin-boilerplate';
import { map, scan } from 'rxjs/operators';

export interface CanLoad {
    setLoading: (loading: boolean) => void;
    resetLoading: () => void;
    loading$: Observable<boolean>;
}

export type CanLoadMixin = Constructor<CanLoad>;

export const MixinLoadableClass = mixinLoadable(Base);

export function mixinLoadable<T extends BaseConstructor = BaseConstructor>(SuperClass: T): CanLoadMixin & T {
    @Directive()
    class Loadable extends SuperClass {
        private counter$$ = new BehaviorSubject(0);
        loading$ = this.counter$$.pipe(map(value => value > 0));

        public setLoading(loading: boolean) {
            const delta = loading ? 1 : - 1;
            const updatedCounter = this.counter$$.getValue() + delta;
            if (updatedCounter <= 0) {
                this.counter$$.next(0);
            } else {
                this.counter$$.next(updatedCounter);
            }
        }

        public resetLoading() {
            this.counter$$.next(0);
        }
    }

    return Loadable;
}
