import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClimbServicesModule } from '../services/climb-services.module';

import { ScheduleService } from './schedule.service';
import { ScheduleVocabService } from './schedule-vocab.service';
import { TaskInstanceStateService } from './services/taskInstance-state.service';
import { TaskInstanceSaveService } from './services/taskInstance-save.service';
import { ScheduleNonTaskSaveService } from './services/scheduleNonTask-save.service';
import { ScheduleNonTaskStateService } from './services/scheduleNonTask-state.service';

@NgModule({
    imports: [
        CommonModule,
        ClimbServicesModule
    ]
})
export class ScheduleSharedModule {
    static forRoot(): ModuleWithProviders<ScheduleSharedModule> {
        return {
          ngModule: ScheduleSharedModule,
          providers: [
            ScheduleService,
              ScheduleVocabService,
              TaskInstanceSaveService,
              TaskInstanceStateService,
              ScheduleNonTaskSaveService,
              ScheduleNonTaskStateService
          ]
        };
    }
}
