<loading-overlay *ngIf="jobPharmaSampleGroupsTableService.loading$ | async" message="Loading"></loading-overlay>
<table class="table table-bordered job-pharma-samples-groups">
    <thead>
        <tr class="detail-grid-header">

            
            <!-- Select -->
            <th *ngIf="!readonly" class="icon-cell">
                <input type="checkbox"
                    name="selectAll"
                    [(ngModel)]="jobPharmaSampleGroupsTableService.allSelected"
                    (ngModelChange)="jobPharmaSampleGroupsTableService.onSelectAll()"
                    title="Select/Clear all" />
            </th>


            <!-- Remove -->
            <th *ngIf="!readonly">
                <ng-container *ngIf="jobPharmaSampleGroupsTableService.canRemoveAnySampleGroup()">
                    <a (click)="jobPharmaSampleGroupsTableService.removeAllEligibleSampleGroups()"
                        class="remove-sample-groups">
                        <i class="fa fa-remove remove-item" title="Delete all eligible sample groups."></i>
                    </a>
                </ng-container>
            </th>


            <!-- Expand -->
            <th>
                <a (click)="jobPharmaSampleGroupsTableService.onBulkExpand()" class="source-toggle-link">
                    <i *ngIf="jobPharmaSampleGroupsTableService.bulkExpanded" class="fa fa-chevron-up" title="Hide all sources"></i>
                    <i *ngIf="!jobPharmaSampleGroupsTableService.bulkExpanded" class="fa fa-chevron-down" title="Show all sources"></i>
                </a>
            </th>


            <!-- Protocol Name -->
            <th *ngIf="visibleColumns.protocol">Protocol</th>


            <!-- Task Name -->
            <th *ngIf="visibleColumns.taskAlias">Task Name</th>


            <!-- Number Of Sources -->
            <th *ngIf="visibleColumns.numSources">Sources</th>


            <!-- Number Of Samples Per Source -->
            <th *ngIf="visibleColumns.numSamples">
                <bulk-edit-header 
                    [headerText]="'Samples per Source'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkNumSamples()">
                    <div modal-content>
                        <input [(ngModel)]="jobPharmaSampleGroupsTableService.bulkNumSamples"
                            type="number"
                            min="1"
                            step="1"
                            class="form-control input-small"
                            name="sampleGroup-bulk-numSamples" />
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Number Of Samples -->
            <th *ngIf="visibleColumns.numTotal">Samples</th>


            <!-- Sample Type -->
            <th *ngIf="visibleColumns.type">
                <bulk-edit-header 
                    [headerText]="'Type'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkSampleTypeKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="jobPharmaSampleGroupsTableService.bulkSampleTypeKey"
                            [vocabChoices]="jobPharmaSampleGroupsTableService.sampleTypes"
                            [keyFormatter]="jobPharmaSampleGroupsTableService.sampleTypeKeyFormatter"
                            [optionFormatter]="jobPharmaSampleGroupsTableService.sampleTypeFormatter"
                            [nullable]="false">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Sample Status -->
            <th *ngIf="visibleColumns.status">
                <bulk-edit-header 
                    [headerText]="'Status'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkSampleStatusKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="jobPharmaSampleGroupsTableService.bulkSampleStatusKey"
                            [vocabChoices]="jobPharmaSampleGroupsTableService.sampleStatuses"
                            [keyFormatter]="jobPharmaSampleGroupsTableService.sampleStatusKeyFormatter"
                            [optionFormatter]="jobPharmaSampleGroupsTableService.sampleStatusFormatter"
                            [nullable]="false">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Preservation Method -->
            <th *ngIf="visibleColumns.preservation && activeFields.includes('C_PreservationMethod_key')">
                <bulk-edit-header 
                    [headerText]="'Preservation'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkPreservationMethodKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="jobPharmaSampleGroupsTableService.bulkPreservationMethodKey"
                            [vocabChoices]="jobPharmaSampleGroupsTableService.preservationMethods"
                            [keyFormatter]="jobPharmaSampleGroupsTableService.preservationMethodKeyFormatter"
                            [optionFormatter]="jobPharmaSampleGroupsTableService.preservationMethodFormatter"
                            [required]="requiredFields.includes('C_PreservationMethod_key')"
                            [nullable]="!requiredFields.includes('C_PreservationMethod_key')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Container Type -->
            <th *ngIf="visibleColumns.containerType && activeFields.includes('Material.C_ContainerType_key')">
                <bulk-edit-header 
                    [headerText]="'Container'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkContainerTypeKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="jobPharmaSampleGroupsTableService.bulkContainerTypeKey"
                            [vocabChoices]="jobPharmaSampleGroupsTableService.containerTypes"
                            [keyFormatter]="jobPharmaSampleGroupsTableService.containerTypeKeyFormatter"
                            [optionFormatter]="jobPharmaSampleGroupsTableService.containerTypeFormatter"
                            [required]="requiredFields.includes('Material.C_ContainerType_key')"
                            [nullable]="!requiredFields.includes('Material.C_ContainerType_key')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Harvest Date -->
            <th *ngIf="visibleColumns.harvestDate && activeFields.includes('DateHarvest')">
                <bulk-edit-header 
                    [headerText]="'Harvest Date'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkDateHarvest()">
                    <div modal-content>
                        <climb-ngb-date #harvestDateControl="ngModel"
                                        name="Harvest Date"
                                        [(ngModel)]="jobPharmaSampleGroupsTableService.bulkDateHarvest"
                                        [required]="requiredFields.includes('DateHarvest')">
                        </climb-ngb-date>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Expiration Date -->
            <th *ngIf="visibleColumns.expirationDate && activeFields.includes('DateExpiration')">
                <bulk-edit-header 
                    [headerText]="'Expiration Date'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkDateExpiration()">
                    <div modal-content>
                        <climb-ngb-date #expirationDateControl="ngModel"
                                        name="Expiration Date"
                                        [(ngModel)]="jobPharmaSampleGroupsTableService.bulkDateExpiration"
                                        [required]="requiredFields.includes('DateExpiration')">
                        </climb-ngb-date>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Time Point -->
            <th *ngIf="visibleColumns.timePoint && activeFields.includes('TimePoint')">
                <bulk-edit-header 
                    [headerText]="'Time Point'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkTimePoint()">
                    <div modal-content class="d-flex flex-row">
                        <input type="number"
                            min="0"
                            step="any"
                            name="sampleGroup-bulk-timePoint"
                            [(ngModel)]="bulkTimePoint"
                            decimalFormatter
                            [decimalPlaces]="3"
                            class="form-control input-small" 
                            [required]="requiredFields.includes('TimePoint')"/>
                        <active-vocab-select [(model)]="jobPharmaSampleGroupsTableService.bulkTimeUnitKey"
                            [vocabChoices]="jobPharmaSampleGroupsTableService.timeUnits"
                            [keyFormatter]="jobPharmaSampleGroupsTableService.timeUnitKeyFormatter"
                            [optionFormatter]="jobPharmaSampleGroupsTableService.timeUnitFormatter"
                            [required]="requiredFields.includes('TimePoint')"
                            [nullable]="!requiredFields.includes('TimePoint')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Sample Subtype -->
            <th *ngIf="visibleColumns.subtype && activeFields.includes('C_SampleSubtype_key')">
                <bulk-edit-header 
                    [headerText]="'Subtype'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkSampleSubtypeKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="jobPharmaSampleGroupsTableService.bulkSampleSubtypeKey"
                            [vocabChoices]="jobPharmaSampleGroupsTableService.sampleSubtypes"
                            [keyFormatter]="jobPharmaSampleGroupsTableService.sampleSubtypeKeyFormatter"
                            [optionFormatter]="jobPharmaSampleGroupsTableService.sampleSubtypeFormatter"
                            [required]="requiredFields.includes('C_SampleSubtype_key')"
                            [nullable]="!requiredFields.includes('C_SampleSubtype_key')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Sample Processing Method -->
            <th *ngIf="visibleColumns.processing && activeFields.includes('C_SampleProcessingMethod_key')">
                <bulk-edit-header 
                    [headerText]="'Processing'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkSampleProcessingMethodKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="jobPharmaSampleGroupsTableService.bulkSampleProcessingMethodKey"
                            [vocabChoices]="jobPharmaSampleGroupsTableService.sampleProcessingMethods"
                            [keyFormatter]="jobPharmaSampleGroupsTableService.sampleProcessingMethodKeyFormatter"
                            [optionFormatter]="jobPharmaSampleGroupsTableService.sampleProcessingMethodFormatter"
                            [required]="requiredFields.includes('C_SampleProcessingMethod_key')"
                            [nullable]="!requiredFields.includes('C_SampleProcessingMethod_key')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Send To -->
            <th *ngIf="visibleColumns.sendTo && activeFields.includes('SendTo')">
                <bulk-edit-header 
                    [headerText]="'Send To'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkSendTo()">
                    <div modal-content>
                        <input [(ngModel)]="jobPharmaSampleGroupsTableService.bulkSendTo"
                            type="text"
                            maxlength="150"
                            class="form-control input-small"
                            name="sampleGroup-bulk-sendTo" 
                            [required]="requiredFields.includes('SendTo')"/>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Sample Analysis Method -->
            <th *ngIf="visibleColumns.analysis && activeFields.includes('C_SampleAnalysisMethod_key')">
                <bulk-edit-header 
                    [headerText]="'Analysis'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkSampleAnalysisMethodKey()">
                    <div modal-content>
                        <active-vocab-select [(model)]="jobPharmaSampleGroupsTableService.bulkSampleAnalysisMethodKey"
                            [vocabChoices]="jobPharmaSampleGroupsTableService.sampleAnalysisMethods"
                            [keyFormatter]="jobPharmaSampleGroupsTableService.sampleAnalysisMethodKeyFormatter"
                            [optionFormatter]="jobPharmaSampleGroupsTableService.sampleAnalysisMethodFormatter"
                            [required]="requiredFields.includes('C_SampleAnalysisMethod_key')"
                            [nullable]="!requiredFields.includes('C_SampleAnalysisMethod_key')">
                        </active-vocab-select>
                    </div>
                </bulk-edit-header>
            </th>


            <!-- Special Instructions -->
            <th *ngIf="visibleColumns.specialInstructions && activeFields.includes('SpecialInstructions')">
                <bulk-edit-header 
                    [headerText]="'Special Instructions'"
                    [readonly]="readonly"
                    (updateClicked)="jobPharmaSampleGroupsTableService.updateBulkSpecialInstructions()">
                    <div modal-content>
                        <textarea 
                            [(ngModel)]="jobPharmaSampleGroupsTableService.bulkSpecialInstructions"
                            [required]="requiredFields.includes('SpecialInstructions')"
                            name="sampleGroup-bulk-specialInstructions"
                            maxlength="1000"
                            class="form-control input-medium">
                        </textarea>
                    </div>
                </bulk-edit-header>
            </th>


        </tr>
    </thead>
    <tbody *ngIf="!(jobPharmaSampleGroupsTableService.loading$ | async)">
        <tr *ngFor="let sg of jobPharmaSampleGroupsTableService.sampleGroups; let index = index"
            [ngClass]="{'selected-row': (!jobPharmaSampleGroupsTableService.isLocked(sg)) && sg.isSelected}"
            class="sample-group-row">
            <ng-container *ngIf="sg.TaskInstance">

                <!-- Select -->
                <td *ngIf="!readonly"
                    class="icon-cell">
                    <input *ngIf="!(sg | hasAssociatedSamples)"
                        type="checkbox"
                        name="select-samples-group-{{index}}"
                        [(ngModel)]="sg.isSelected"
                        (ngModelChange)="jobPharmaSampleGroupsTableService.onSelect()" />
                </td>
    
    
                <!-- Remove -->
                <td class="icon-cell"
                    *ngIf="!readonly">
                    <a *ngIf="!jobPharmaSampleGroupsTableService.isLocked(sg)"
                        (click)="jobPharmaSampleGroupsTableService.removeSampleGroup(sg)" 
                        class="remove-sample-group">
                        <i class="fa fa-remove remove-item" title="Remove Sample Group"></i>
                    </a>
                </td>
    
    
                <!-- Expand -->
                <td class="icon-cell">
                    <a (click)="jobPharmaSampleGroupsTableService.onExpand(sg)"
                        class="source-toggle-link"
                       *ngIf="sg.numSources > 0">
                        <i *ngIf="sg.expanded" class="fa fa-chevron-up" title="Hide sources"></i>
                        <i *ngIf="!sg.expanded" class="fa fa-chevron-down" title="Show sources"></i>
                    </a>
                </td>
    
    
                <!-- Protocol -->
                <td *ngIf="visibleColumns.protocol"
                    [ngClass]="sg.classes?.task">
                    <ng-container *ngIf="sg.taskFirst" class="sample-group-protocol-name">
                        {{sg.TaskInstance?.ProtocolInstance?.ProtocolAlias}}
                    </ng-container>
                </td>
    
    
                <!-- Task -->
                <td *ngIf="visibleColumns.taskAlias"
                    [ngClass]="sg.classes?.task">
                    <ng-container *ngIf="sg.taskFirst" class="sample-group-task-name">
                        {{sg.TaskInstance?.TaskAlias}}
                    </ng-container>
                </td>
    
    
                <!-- NumSources -->
                <td *ngIf="visibleColumns.numSources">
                    <div *ngIf="sg.numSources && sg.NumberOfAnimalSourcesCount">
                            <ng-container *ngIf="sg.expanded">
                                <ng-container *ngFor="let material of sg.SampleGroupSourceMaterial">
                                    <div class="label-with-remove" *ngIf= "material.Material && material.Material.Animal">
                                        <a *ngIf="jobPharmaSampleGroupsTableService.canRemoveSampleGroupSourceMaterial(sg) && !readonly"
                                            (click)="jobPharmaSampleGroupsTableService.removeSampleGroupSourceMaterial(sg, material)" 
                                            class="remove-animal-sample-group">
                                            <i class="fa fa-remove remove-animal-sample-group" title="Remove Sample Group Source"></i>
                                        </a>
                                        {{material.Material.Animal?.AnimalName}}
                                    </div>
                                </ng-container>
                            </ng-container>
    
                            <ng-container *ngIf="!sg.expanded && sg.NumberOfAnimalSourcesCount">
                                {{sg.NumberOfAnimalSourcesCount}}
                                <img src="/images/animals.png" width="12" height="12" alt="Animal" title="Animal" />
                            </ng-container>
                    </div>
                    <div *ngIf="sg.numSources && sg.NumberOfSampleSourcesCount">
                        <ng-container *ngIf="sg.expanded">
                            <div *ngFor="let sample of sg.SampleGroupSourceMaterial">
                                <div  class="label-with-remove"  *ngIf = "sample.Material && sample.Material.Sample">
                                    <a *ngIf="jobPharmaSampleGroupsTableService.canRemoveSampleGroupSourceMaterial(sg) && !readonly"
                                        (click)="jobPharmaSampleGroupsTableService.removeSampleGroupSourceMaterial(sg, sample)" 
                                        class="remove-animal-sample-group">
                                        <i class="fa fa-remove remove-animal-sample-group" title="Remove Sample Group Source"></i>
                                    </a>
                                    {{sample.Material.Sample?.SampleName}}
                                </div>
    
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!sg.expanded && sg.NumberOfSampleSourcesCount">
                            {{sg.NumberOfSampleSourcesCount}}
                            <img src="/images/samples.png" width="12" height="12" alt="Sample" title="Sample" />
                        </ng-container>
                    </div>
                    <div *ngIf="sg.numSources && sg.NumberOfAnimalPlaceholderSourcesCount">
                        <ng-container *ngIf="sg.expanded">
                            <div *ngFor="let placeholder of sg.SampleGroupSourceMaterial">
                                <div class="label-with-remove" *ngIf = "placeholder.AnimalPlaceholder && !placeholder.AnimalPlaceholder.Material">
                                    <a *ngIf="jobPharmaSampleGroupsTableService.canRemoveSampleGroupSourceMaterial(sg) && !readonly"
                                        (click)="jobPharmaSampleGroupsTableService.removeSampleGroupSourceMaterial(sg, placeholder)" 
                                        class="remove-animal-sample-group" >
                                        <i class="fa fa-remove remove-animal-sample-group" title="Remove Sample Group Source"></i>
                                    </a>
                                    {{placeholder.AnimalPlaceholder.Name}}
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!sg.expanded">
                            {{sg.NumberOfAnimalPlaceholderSourcesCount}}
                            <img src="/images/AnimalPlaceholder.png" width="12" height="12" alt="Placeholder" title="Placeholder" />
                        </ng-container>
                    </div>
                    <span *ngIf="!sg.numSources || sg.numSources === 0">
                        {{sg.numSources}}
                    </span>
                </td>
    
    
                <!-- NumSamples -->
                <td *ngIf="visibleColumns.numSamples">
                    <input 
                        [(ngModel)]="sg.NumSamples"
                        (ngModelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'NumSamples', sg)"
                        [disabled]="jobPharmaSampleGroupsTableService.isLocked(sg)"
                        type="number"
                        min="0"
                        step="1"
                        class="form-control input-small"
                        name="sampleGroup-{{sg.C_SampleGroup_key}}-numSamples" />
                </td>
    
    
                <!-- NumTotal -->
                <td *ngIf="visibleColumns.numTotal"
                    class="num-cell col-size-fit">
                    <ng-container>
                        {{ sg.numSources * sg.NumSamples }}
                    </ng-container>
                </td>
    
    
                <!-- Type -->
                <td *ngIf="visibleColumns.type">
                    <active-vocab-select 
                        [(model)]="sg.C_SampleType_key"
                        (modelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'C_SampleType_key', sg)"
                        [vocabChoices]="jobPharmaSampleGroupsTableService.sampleTypes"
                        [keyFormatter]="jobPharmaSampleGroupsTableService.sampleTypeKeyFormatter"
                        [optionFormatter]="jobPharmaSampleGroupsTableService.sampleTypeFormatter"
                        [nullable]="false"
                        [readonly]="jobPharmaSampleGroupsTableService.isLocked(sg)">
                    </active-vocab-select>
                </td>
    
    
                <!-- Status -->
                <td *ngIf="visibleColumns.status">
                    <active-vocab-select 
                        [(model)]="sg.C_SampleStatus_key"
                        (modelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'C_SampleStatus_key', sg)"
                        [vocabChoices]="jobPharmaSampleGroupsTableService.sampleStatuses"
                        [keyFormatter]="jobPharmaSampleGroupsTableService.sampleStatusKeyFormatter"
                        [optionFormatter]="jobPharmaSampleGroupsTableService.sampleStatusFormatter"
                        [nullable]="false"
                        [readonly]="jobPharmaSampleGroupsTableService.isLocked(sg)">
                    </active-vocab-select>
                </td>
    
    
                <!-- Preservation -->
                <td *ngIf="visibleColumns.preservation && activeFields.includes('C_PreservationMethod_key')">
                    <active-vocab-select 
                        [(model)]="sg.C_PreservationMethod_key"
                        (modelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'C_PreservationMethod_key', sg)"
                        [vocabChoices]="jobPharmaSampleGroupsTableService.preservationMethods"
                        [keyFormatter]="jobPharmaSampleGroupsTableService.preservationMethodKeyFormatter"
                        [optionFormatter]="jobPharmaSampleGroupsTableService.preservationMethodFormatter"
                        [required]="requiredFields.includes('C_PreservationMethod_key')"
                        [nullable]="!requiredFields.includes('C_PreservationMethod_key')"
                        [readonly]="jobPharmaSampleGroupsTableService.isLocked(sg)">
                    </active-vocab-select>
                </td>
    
    
                <!-- Container Type-->
                <td *ngIf="visibleColumns.containerType && activeFields.includes('Material.C_ContainerType_key')">
                    <active-vocab-select 
                        [(model)]="sg.C_ContainerType_key"
                        (modelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'C_ContainerType_key', sg)"
                        [vocabChoices]="jobPharmaSampleGroupsTableService.containerTypes"
                        [keyFormatter]="jobPharmaSampleGroupsTableService.containerTypeKeyFormatter"
                        [optionFormatter]="jobPharmaSampleGroupsTableService.containerTypeFormatter"
                        [required]="requiredFields.includes('Material.C_ContainerType_key')"
                        [nullable]="!requiredFields.includes('Material.C_ContainerType_key')"
                        [readonly]="jobPharmaSampleGroupsTableService.isLocked(sg)"></active-vocab-select>
                </td>
    
    
                <!-- Harvest Date -->
                <td *ngIf="visibleColumns.harvestDate && activeFields.includes('DateHarvest')">
                    <climb-ngb-date #dateControl="ngModel"
                        [(ngModel)]="sg.DateHarvest"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'DateHarvest', sg)"
                        [disabled]="jobPharmaSampleGroupsTableService.isLocked(sg)" 
                        [container]="'body'"
                        [required]="requiredFields.includes('DateHarvest')"></climb-ngb-date>
                </td>
    
    
                <!-- Expiration Date -->
                <td *ngIf="visibleColumns.expirationDate && activeFields.includes('DateExpiration')">
                    <climb-ngb-date #dateControl="ngModel"
                        [(ngModel)]="sg.DateExpiration"
                        (ngModelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'DateExpiration', sg)"
                        [ngModelOptions]="{ standalone: true }"
                        [disabled]="jobPharmaSampleGroupsTableService.isLocked(sg)" 
                        [container]="'body'"
                        [required]="requiredFields.includes('DateExpiration')"></climb-ngb-date>
                </td>
    
    
                <!-- Time Point -->
                <td *ngIf="visibleColumns.timePoint && activeFields.includes('TimePoint')">
                    <div class="d-flex flex-row">
                        <input 
                            type="number"
                            min="0"
                            step="any"
                            name="sampleGroup-{{sg.C_SampleGroup_key}}-timePoint"
                            [(ngModel)]="sg.TimePoint"
                            (ngModelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'TimePoint', sg)"
                            decimalFormatter
                            [decimalPlaces]="3"
                            class="form-control input-small"
                            [disabled]="jobPharmaSampleGroupsTableService.isLocked(sg)" 
                            [required]="requiredFields.includes('TimePoint')"/>
                        <active-vocab-select 
                            [(model)]="sg.C_TimeUnit_key"
                            (modelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'C_TimeUnit_key', sg)"
                            [vocabChoices]="jobPharmaSampleGroupsTableService.timeUnits"
                            [keyFormatter]="jobPharmaSampleGroupsTableService.timeUnitKeyFormatter"
                            [optionFormatter]="jobPharmaSampleGroupsTableService.timeUnitFormatter"
                            [readonly]="jobPharmaSampleGroupsTableService.isLocked(sg)"
                            [required]="requiredFields.includes('TimePoint')"
                            [nullable]="!requiredFields.includes('TimePoint')"></active-vocab-select>
                    </div>
                </td>
    
    
                <!-- Subtype -->
                <td *ngIf="visibleColumns.subtype && activeFields.includes('C_SampleSubtype_key')">
                    <active-vocab-select 
                        [(model)]="sg.C_SampleSubtype_key"
                        (modelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'C_SampleSubtype_key', sg)"
                        [vocabChoices]="jobPharmaSampleGroupsTableService.sampleSubtypes"
                        [keyFormatter]="jobPharmaSampleGroupsTableService.sampleSubtypeKeyFormatter"
                        [optionFormatter]="jobPharmaSampleGroupsTableService.sampleSubtypeFormatter"
                        [required]="requiredFields.includes('C_SampleSubtype_key')"
                        [nullable]="!requiredFields.includes('C_SampleSubtype_key')"
                        [readonly]="jobPharmaSampleGroupsTableService.isLocked(sg)"></active-vocab-select>
                </td>
    
    
                <!-- Processing -->
                <td *ngIf="visibleColumns.processing && activeFields.includes('C_SampleProcessingMethod_key')">
                    <active-vocab-select 
                        [(model)]="sg.C_SampleProcessingMethod_key"
                        (modelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'C_SampleProcessingMethod_key', sg)"
                        [vocabChoices]="jobPharmaSampleGroupsTableService.sampleProcessingMethods"
                        [keyFormatter]="jobPharmaSampleGroupsTableService.sampleProcessingMethodKeyFormatter"
                        [optionFormatter]="jobPharmaSampleGroupsTableService.sampleProcessingMethodFormatter"
                        [required]="requiredFields.includes('C_SampleProcessingMethod_key')"
                        [nullable]="!requiredFields.includes('C_SampleProcessingMethod_key')"
                        [readonly]="jobPharmaSampleGroupsTableService.isLocked(sg)"></active-vocab-select>
                </td>
    
    
                <!-- Send To -->
                <td *ngIf="visibleColumns.sendTo && activeFields.includes('SendTo')">
                    <input 
                        [(ngModel)]="sg.SendTo"
                        (ngModelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'SendTo', sg)"
                        type="text"
                        maxlength="150"
                        class="form-control input-small"
                        name="sampleGroup-sendTo"
                        [disabled]="jobPharmaSampleGroupsTableService.isLocked(sg)"
                        [required]="requiredFields.includes('SendTo')"/>
                </td>
    
    
                <!-- Analysis -->
                <td *ngIf="visibleColumns.analysis && activeFields.includes('C_SampleAnalysisMethod_key')">
                    <active-vocab-select 
                        [(model)]="sg.C_SampleAnalysisMethod_key"
                        (modelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'C_SampleAnalysisMethod_key', sg)"
                        [vocabChoices]="jobPharmaSampleGroupsTableService.sampleAnalysisMethods"
                        [keyFormatter]="jobPharmaSampleGroupsTableService.sampleAnalysisMethodKeyFormatter"
                        [optionFormatter]="jobPharmaSampleGroupsTableService.sampleAnalysisMethodFormatter"
                        [required]="requiredFields.includes('C_SampleAnalysisMethod_key')"
                        [nullable]="!requiredFields.includes('C_SampleAnalysisMethod_key')"
                        [readonly]="jobPharmaSampleGroupsTableService.isLocked(sg)"></active-vocab-select>
                </td>
    
    
                <!-- Special Instructions -->
                <td *ngIf="visibleColumns.specialInstructions && activeFields.includes('SpecialInstructions')">
                    <textarea 
                        [(ngModel)]="sg.SpecialInstructions"
                        (ngModelChange)="jobPharmaSampleGroupsTableService.recordSampleGroupChange($event, 'SpecialInstructions', sg)"
                        [required]="requiredFields.includes('SpecialInstructions')"
                        [disabled]="jobPharmaSampleGroupsTableService.isLocked(sg)"
                        name="sampleGroup-specialInstructions-{{index}}"
                        maxlength="1000"
                        rows="1"
                        class="form-control input-medium">
                    </textarea>
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>
