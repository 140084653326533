import { Injectable } from '@angular/core';
import { GlpBaseFacetStateService } from '../../services/glp-base-facet-state.service';
import {
    Entity as InternalEntity,
    ScheduleNonTask
}
    from '../../common/types';
import { DataManagerService } from '../../services/data-manager.service';
import { Entity as BreezeEntity } from 'breeze-client';

@Injectable()
export class ScheduleNonTaskStateService extends GlpBaseFacetStateService<ScheduleNonTask> {

    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(scheduleNonTask: InternalEntity<ScheduleNonTask>): BreezeEntity[] {
        const changes: any = [];
        return changes;
    }
    
    discardChanges(scheduleNonTask: InternalEntity<ScheduleNonTask>): void {
        // TODO: implement facet-level discard operation after release of GLP
        throw new Error('Method not implemented.');
    }

    getDeleteNavPropertyChanges(entity: InternalEntity<ScheduleNonTask>): BreezeEntity[] {
        const changes: any = [];       
        return changes;
    }    
}
