import type { Subscription } from 'rxjs';
import {
    Injectable
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    apiServiceBaseUri,
    apiServiceBaseUriAdmin
} from '../config/climb-web-settings';
import { breeze } from './breeze';
import { EntityManager } from 'breeze-client';
import { LocalStorageService } from './local-storage.service';

import { FacetLevelSaveOptions, removeValidators } from './breeze-helpers';
import { Entity } from '@common/types';

declare let OData: any;

// localstorage key prefixes
const DATA_NAME = 'data';
const ADMIN_NAME = 'admin';
type MetadataName = 'data' | 'admin';
const SCHEMA_KEY_SUFFIX = 'schemaKey';
const SCHEMA_SUFFIX = 'breezeSchema';

@Injectable()
export class EntityManagerFactoryService {
    dataServiceName = apiServiceBaseUri + '/odata/';
    adminServiceName = apiServiceBaseUriAdmin + '/odata/';

    // state variables
    private _migrationIdPromise: Promise<string>;
    private _adminMigrationIdPromise: Promise<string>;

    constructor(
        private localStorageService: LocalStorageService,
        private http: HttpClient
    ) {
        this.configureBreeze();
    }

    newDataManager(): Promise<EntityManager> {
        return this.getCachedMetadata(DATA_NAME).then( (metadataStore: any) => {

            let manager: EntityManager = null;

            if (metadataStore) {
                debug("loading breeze schema from localstorage");
                manager = new EntityManager({
                    serviceName: this.dataServiceName,
                    metadataStore
                });
                removeValidators(manager.metadataStore);

            } else {
                debug("refreshing breeze schema from OData API");
                manager = new EntityManager({ serviceName: this.dataServiceName });
                manager.fetchMetadata().then(() => {
                    this.cacheMetadata(manager.metadataStore, DATA_NAME);
                    removeValidators(manager.metadataStore);
                });
            }

            return manager;
        }).catch( (err: Error) => {
            console.error(err);
        }) as Promise<EntityManager>;
    }

    newAdminManager(): Promise<EntityManager> {
        return this.getCachedMetadata(ADMIN_NAME).then( (metadataStore: any) => {

            let manager: EntityManager = null;

            if (metadataStore) {
                debug("loading breeze admin schema from localstorage");
                manager = new EntityManager({
                    serviceName: this.adminServiceName,
                    metadataStore
                });

            } else {
                debug("refreshing breeze admin schema from OData API");
                manager = new EntityManager({ serviceName: this.adminServiceName });
                manager.fetchMetadata().then(() => {
                    this.cacheMetadata(manager.metadataStore, ADMIN_NAME);
                });
            }

            return manager;
        }).catch((err: Error) => {
            console.error(err);
        }) as Promise<EntityManager>;
    }

    /**
     * Get cached schema metadata for either 'data' or 'admin' context
     *
     * @param metadataName -
     */
    private getCachedMetadata(metadataName: MetadataName): Promise<any> {
        const schemaKeyCacheId = metadataName + "." + SCHEMA_KEY_SUFFIX;
        const schemaCacheId = metadataName + "." + SCHEMA_SUFFIX;

        return this.getSchemaKey(metadataName).then( (schemaKey) => {

            const storedSchemaKey = this.localStorageService.get(schemaKeyCacheId);

            if (storedSchemaKey !== schemaKey) {
                debug("invalidating " + metadataName +
                    "breeze schema, because of new (url - migrationId): " + schemaKey
                );
                this.localStorageService.remove(schemaKeyCacheId);
                this.localStorageService.remove(schemaCacheId);
                return null;
            }

            let metadataStore = null;
            const storedSchema = this.localStorageService.get(schemaCacheId);
            if (storedSchema) {
                metadataStore = new breeze.MetadataStore();
                metadataStore.importMetadata(storedSchema);
            }

            return metadataStore;
        });
    }

    /**
     * Cache schema metadata for either 'data' or 'admin' context
     *   Only if current migration Id has changed
     *
     * @param metadataStore - breeze MetadataStore
     * @param metadataName - data or admin
     */
    private cacheMetadata(metadataStore: any, metadataName: MetadataName) {
        const schemaKeyCacheId = metadataName + "." + SCHEMA_KEY_SUFFIX;
        const schemaCacheId = metadataName + "." + SCHEMA_SUFFIX;

        this.getSchemaKey(metadataName).then( (schemaKey) => {

            const storedSchemaKey = this.localStorageService.get(schemaKeyCacheId);

            if (storedSchemaKey !== schemaKey) {
                debug("caching " + metadataName + " breeze schema in local storage");
                this.localStorageService.set(schemaKeyCacheId, schemaKey);
                this.localStorageService.set(schemaCacheId, metadataStore.exportMetadata());
            }
        });
    }

    /**
     * builds and returns a schema key based on
     *   current migrationId and serviceUrl
     * returns promise
     */
    private getSchemaKey(metadataName: MetadataName): Promise<string> {
        return this.getCurrentMigrationId(metadataName).then( (migrationId) => {
            return this.makeSchemaKey(metadataName, migrationId);
        });
    }


    private getCurrentMigrationId(metadataName: MetadataName): Promise<string> {
        if (metadataName === DATA_NAME) {
            return this.getDataMigrationId();
        } else if (metadataName === ADMIN_NAME) {
            return this.getAdminMigrationId();
        }
        throw new Error("metadataName '" + metadataName +
            "' not recognized. Can't get migration Id"
        );
    }

    private getDataMigrationId(): Promise<string> {

        if (!this._migrationIdPromise) {
            const serviceUrl = apiServiceBaseUri + 'api/ClimbInfo/GetCurrentMigrationVersion';
            this._migrationIdPromise = this.http.get(serviceUrl, { responseType: 'text' }).toPromise();
        }

        return this._migrationIdPromise;
    }

    private getAdminMigrationId(): Promise<string> {

        if (!this._adminMigrationIdPromise) {
            const serviceUrl = apiServiceBaseUriAdmin + 'api/ClimbInfo/GetCurrentMigrationVersion';
            this._adminMigrationIdPromise = this.http.get(serviceUrl, { responseType: 'text' }).toPromise();
        }

        return this._adminMigrationIdPromise;
    }

    private makeSchemaKey(metadataName: MetadataName, migrationId: string): string {
        let serviceUrl = this.dataServiceName;
        if (metadataName === ADMIN_NAME) {
            serviceUrl = this.adminServiceName;
        }
        return serviceUrl + " - " + migrationId;
    }

    /**
     * Configure Breeze to use Web API OData for query and save
     */
    private configureBreeze() {
        const dsAdapter: any = breeze.config.initializeAdapterInstance(
            'dataService', 'webApiOData', true
        )
        // Add the interceptor
            dsAdapter.changeRequestInterceptor = function (saveContext: any, saveBundle: any) {
            this.getRequest = (request: any, entity: Entity<any>, index: any) => {
                // Send information about the logical transaction id and facet name to the backend for audit
                if (saveBundle?.saveOptions instanceof FacetLevelSaveOptions) {
                    request.headers.OperationId = saveBundle.saveOptions.operationId ?? '';
                    request.headers.FacetName = saveBundle.saveOptions.facetName ?? '';
                    request.headers.ReasonForChange = JSON.stringify(saveBundle.saveOptions.reasonForChange) ?? '';
                }
                return request;
            };

            this.done = function (requests: any) {
                // do nothing when done
            };
        }
        this.setHttpClient(this.http);
    }

    private setHttpClient(httpClient: HttpClient) {
        const requestControl = (subscription: Subscription) => ({
            abort() {
                subscription.unsubscribe();
            }
        });
        
        const isSuccessfulResponse = (statusCode: number): boolean => statusCode >= 200 && statusCode <= 299;

        type Request = {
            method: 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';
            requestUri: string;
            headers: HttpHeaders | { [p: string]: string | string[] }
            body?: Record<string, unknown>;
            enableJsonpCallback?: boolean;
            callbackParameterName?: string;
        };
        type Response = {
            requestUri?: string;
            statusCode: number;
            statusText?: string;
            headers: { [p: string]: string | string[] };
            body: string | Record<string, unknown>;
        };
        type SuccessFn = (response: Response) => void;
        type ErrorFn = (error: { message: string, request: Request, response: Response } | any) => void;

        // replace default datajs httpClient with Angular HttpClient
        OData.defaultHttpClient = {
            request(request: Request, success: SuccessFn, error: ErrorFn) {
                const {
                    method = 'GET',
                    requestUri: url,
                    body,
                    enableJsonpCallback = false,
                    callbackParameterName = '$callback',
                } = request;

                let headers: HttpHeaders | { [p: string]: string | string[] } = request.headers;
                const requestData = (request as any).data;

                const appendToHeaders = (headerName: string, headerValue: string | undefined) => {
                    if (headerValue){
                        if (headers instanceof HttpHeaders) {
                            headers = headers.append(headerName, headerValue);
                        } else {
                            headers[headerName] = headerValue;
                        }
                    }
                }

                if (requestData?.__batchRequests?.length > 0 && requestData.__batchRequests[0].__changeRequests?.length > 0) {
                    const batchHeaders = requestData.__batchRequests[0].__changeRequests[0].headers;
                    const facetName = batchHeaders['FacetName'];
                    appendToHeaders('X-Header-FacetName', facetName);

                    const operationId = batchHeaders['OperationId'];
                    appendToHeaders('X-Header-OperationId', operationId);

                    const reasonForChange = batchHeaders['ReasonForChange'];
                    appendToHeaders('X-Header-ReasonForChange', reasonForChange);
                }
            
                if (enableJsonpCallback) {
                    const jsonpSubscription = httpClient.jsonp(url, callbackParameterName)
                        .subscribe((data: Record<string, unknown>) => {
                            success({
                                body: data,
                                statusCode: 200,
                                headers: { 'Content-Type': 'application/json' },
                            });
                        }, error);

                    return requestControl(jsonpSubscription);
                }

                const { origin, pathname, searchParams } = new URL(url);

                const xhrSubscription = httpClient.request(method, origin + pathname, {
                    headers,
                    body,
                    params: Object.fromEntries(searchParams.entries()),
                    observe: 'response',
                    responseType: 'text',
                }).subscribe((data) => {
                    const statusCode = data.status;
                    const responseHeaders = data.headers
                        .keys()
                        .reduce((acc, key) => ({
                            ...acc,
                            [key]: data.headers.get(key),
                        }), {});

                    const response: Response = {
                        requestUri: data.url,
                        statusCode,
                        statusText: data.statusText,
                        headers: responseHeaders,
                        body: data.body,
                    };

                    if (!isSuccessfulResponse(statusCode)) {
                        error({ message: 'HTTP request failed', request, response });
                    }

                    success(response);
                }, error);

                return requestControl(xhrSubscription);
            }
        };
    }

}

// TODO: figure out proper logging in Angular 2
function debug(text: string) {
    // eslint-disable-next-line
    console.log(text);
}
