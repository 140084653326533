import { Injectable, Injector } from "@angular/core";
import { JobPharmaSamplesGroupsTableComponent } from "./job-pharma-samples-groups-table.component";
import { 
    JobPharmaCoreService, 
    JobPharmaDataAccessService, 
    JobPharmaDetailService, 
    SampleGroupChange, 
    SampleGroupChangesMap, 
    SampleGroupExtended 
} from "../../services";
import { VocabularyService } from "src/app/vocabularies/vocabulary.service";
import { LocationService } from "src/app/locations/location.service";
import { LoggingService } from "@services/logging.service";
import { ConfirmService } from "@common/confirm";
import { CanLoadMixin, mixinLoadable } from "@common/mixins/loadable.mixin";
import { Base, applyMixins } from "@common/mixins";
import { SampleGroup, SampleGroupSourceMaterial } from "@common/types";
import { dateControlValidator } from "@common/util/date-control.validator";
import { softCompare } from "@common/util";
import { isEmpty } from "lodash";
import { hasEndStateTasks } from "src/app/jobs/utils/has-end-state-tasks";
import { hasAssociatedSamples } from "src/app/jobs/utils/has-associated-samples";
import { SaveCancellationToken } from "@services/save-changes.service";

export interface SampleGroupsChangeLookup { 
    [key: number]: { 
        sampleGroup: SampleGroupExtended,
        originalValues: SampleGroupChange,
        pendingChanges?: SampleGroupChange
    } 
}

const ERROR_LOADING_SAMPLE_GROUPS = "Error loading sample groups"
const ERROR_LOADING_SAMPLE_GROUP_SOURCES = "Error loading sample groups sources";
const ERROR_LOADING_VOCABULARIES = "Error loading vocabularies";
const ERROR_HANDLING_SAMPLE_GROUP_CHANGES = "Error handling sample group changes";

const Mixins: CanLoadMixin & typeof Base = applyMixins(mixinLoadable);

@Injectable()
export class JobPharmaSampleGroupsTableService extends Mixins {
    readonly LOG_TAG = 'job-pharma-samples-groups-table-service';

    constructor(
        injector: Injector,
        private jobPharmaDataAccessService: JobPharmaDataAccessService,
        private jobPharmaCoreService: JobPharmaCoreService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private vocabularyService: VocabularyService,
        private locationService: LocationService,
        private loggingService: LoggingService,
        private confirmService: ConfirmService
    ) {
        super(injector);
    }

    private _component: JobPharmaSamplesGroupsTableComponent;

    private _sampleGroups: SampleGroupExtended[] = []; 
    get sampleGroups() {
        return this._sampleGroups;
    }

    private _sampleGroupsChangeLookup: SampleGroupsChangeLookup = {};
    get sampleGroupsChangeLookup() {
        return this._sampleGroupsChangeLookup;
    }

    // VOCABULARIES
    preservationMethods: any[];
    sampleStatuses: any[];
    containerTypes: any[];
    sampleTypes: any[];
    timeUnits: any[];
    sampleSubtypes: any[];
    sampleAnalysisMethods: any[];
    sampleProcessingMethods: any[];

    // BULK MODELS
    bulkNumSamples: number;
    bulkPreservationMethodKey: number;
    bulkSampleStatusKey: number;
    bulkSampleTypeKey: number;
    bulkContainerTypeKey: number;
    bulkDateHarvest: Date;
    bulkDateExpiration: Date;
    bulkTimePoint: number;
    bulkTimeUnitKey: number;
    bulkSampleSubtypeKey: number;
    bulkSampleProcessingMethodKey: number;
    bulkSendTo: string;
    bulkSampleAnalysisMethodKey: number;
    bulkSpecialInstructions: string;

    allSelected: boolean;
    allExpanded: boolean;
    bulkExpanded: boolean;


    /**
    * TODO: Remove; this was just to maintain existing behavior which depended on 
    * component specific fields such as readonly and certain ngModel controls
    *  */
    public register(component: JobPharmaSamplesGroupsTableComponent) {
        if (this._component) {
            throw new Error("Component already registered");
        }
        this._component = component;
    }

    public async loadSampleGroups(jobKey: number): Promise<void> {
        try {
            this.setLoading(true);
            this._sampleGroups = await this.jobPharmaDataAccessService.getSampleGroups(jobKey) as SampleGroupExtended[];
            if (this.sampleGroups.length) {
                this.processSampleGroups();
                await this.jobPharmaCoreService.populateSampleGroupsMetadata(this.sampleGroups);
            }
        } catch (err) {
            this.loggingService.logError(ERROR_LOADING_SAMPLE_GROUPS, err, this.LOG_TAG, true);
        } finally {
            this.setLoading(false);
        }
    }

    public async refreshSampleGroupsMetadata() {
        await this.jobPharmaCoreService.populateSampleGroupsMetadata(this.sampleGroups);
    }

    private processSampleGroups(): void {
        this._sampleGroupsChangeLookup = {};

        const uniqueSampleGroups = new Set<number>();
        for (const sg of this.sampleGroups) {
            if (!uniqueSampleGroups.has(sg.C_TaskInstance_key)) {
                uniqueSampleGroups.add(sg.C_TaskInstance_key);
                sg.taskFirst = true;
            }

            sg.classes = {
                task: { 'task-extra': !sg.taskFirst },
            };

            this.sampleGroupsChangeLookup[sg.C_SampleGroup_key] = { 
                sampleGroup: sg,
                originalValues: {
                    sampleGroup: sg,
                    C_ContainerType_key: sg.C_ContainerType_key,
                    C_PreservationMethod_key: sg.C_PreservationMethod_key,
                    C_SampleAnalysisMethod_key: sg.C_SampleAnalysisMethod_key,
                    C_SampleProcessingMethod_key: sg.C_SampleProcessingMethod_key,
                    C_SampleStatus_key: sg.C_SampleStatus_key,
                    C_SampleSubtype_key: sg.C_SampleSubtype_key,
                    C_SampleType_key: sg.C_SampleType_key,
                    C_TimeUnit_key: sg.C_TimeUnit_key,
                    DateExpiration: sg.DateExpiration,
                    DateHarvest: sg.DateHarvest,
                    NumSamples: sg.NumSamples,
                    SendTo: sg.SendTo,
                    TimePoint: sg.TimePoint ?? 0,
                    SpecialInstructions: sg.SpecialInstructions
                }
            };
        }
    }

    public async loadVocabularies() {
        try {
            this.setLoading(true);
            return await Promise.all([
                this.vocabularyService.getCV('cv_PreservationMethods', null, true).then((data: any[]) => {
                    this.preservationMethods = data;
                }),
                this.vocabularyService.getCV('cv_SampleStatuses', null, true).then((data: any[]) => {
                    this.sampleStatuses = data;
                }),
                this.locationService.getContainerTypes('Sample').then((data) => {
                    this.containerTypes = data;
                }),
                this.vocabularyService.getCV('cv_SampleTypes', null, true).then((data: any[]) => {
                    this.sampleTypes = data;
                }),
                this.vocabularyService.getCV('cv_TimeUnits', null, true).then((data: any[]) => {
                    this.timeUnits = data;
                }),
                this.vocabularyService.getCV('cv_SampleSubtypes', null, true).then((data: any[]) => {
                    this.sampleSubtypes = data;
                }),
                this.vocabularyService.getCV('cv_SampleProcessingMethods', null, true).then((data: any[]) => {
                    this.sampleProcessingMethods = data;
                }),
                this.vocabularyService.getCV('cv_SampleAnalysisMethods', null, true).then((data: any[]) => {
                    this.sampleAnalysisMethods = data;
                }),
            ]);
        } catch (err) {
            this.loggingService.logError(ERROR_LOADING_VOCABULARIES, err, this.LOG_TAG, true);
        } finally {
            this.setLoading(false);
        }
    }

    public async loadSampleGroupSourceMaterials(sampleGroupKeys: number[]): Promise<void> {
        try {
            this.setLoading(true);
            await this.jobPharmaDataAccessService.getSampleGroupSourceMaterials(sampleGroupKeys);
        } catch (err) {
            this.loggingService.logError(ERROR_LOADING_SAMPLE_GROUP_SOURCES, err, this.LOG_TAG, true);
        } finally {
            this.setLoading(false);
        }
    }

    public async loadSampleGroupSourceMaterial(sampleGroupKey: number): Promise<void> {
        try {
            await this.jobPharmaDataAccessService.getSampleGroupSourceMaterials([sampleGroupKey]);
        } catch (err) {
            this.loggingService.logError(ERROR_LOADING_SAMPLE_GROUP_SOURCES, err, this.LOG_TAG, true);
        }
    }

    public canRemoveSampleGroupSourceMaterial(sampleGroup: SampleGroupExtended): boolean {
        const isSampleGroupEmpty = !hasAssociatedSamples(sampleGroup);
        if (!this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            return isSampleGroupEmpty;
        }

        const isAnyTaskInEndState = hasEndStateTasks(sampleGroup);
        return isSampleGroupEmpty && !isAnyTaskInEndState;
    }

    public async removeSampleGroupSourceMaterial(sampleGroup: SampleGroupExtended, sourceMaterial: SampleGroupSourceMaterial) {
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag() && hasEndStateTasks(sampleGroup)) {
            return;
        }

        const result = await this.handleRemoveSampleGroupSourceMaterialModal();
        if (softCompare(result, "extra")) {
            const sampleGroupSourceMaterialsToRemove = [];
            for (const sg of this.sampleGroups) {
                if (hasAssociatedSamples(sg)) {
                    continue;
                }

                for (const sgsm of sg.SampleGroupSourceMaterial) {
                    if (sourceMaterial.C_Material_key) {
                        if (sgsm.C_Material_key === sourceMaterial.C_Material_key) {
                            sampleGroupSourceMaterialsToRemove.push(sgsm);
                        }
                    } else if (sourceMaterial.C_AnimalPlaceholder_key) {
                        if (sgsm.C_AnimalPlaceholder_key === sourceMaterial.C_AnimalPlaceholder_key) {
                            sampleGroupSourceMaterialsToRemove.push(sgsm)
                        }
                    }
                }
            }

            if (!sampleGroupSourceMaterialsToRemove.length) {
                return;
            }

            this.jobPharmaDataAccessService.removeSampleGroupSourceMaterials(sampleGroupSourceMaterialsToRemove);
        } else {
            this.jobPharmaDataAccessService.removeSampleGroupSourceMaterials([sourceMaterial]);
        }

        this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
        this.jobPharmaDetailService.tabRefresh('tasks', 'list');
    }

    private async handleRemoveSampleGroupSourceMaterialModal(): Promise<string> {
        try {
            return await this.confirmService.confirm({
                title: "Delete Sample Group",
                message: `Delete sample group? Note: Only the sample group will be deleted for the individual animal.
                    Choosing to delete all samples groups for the animal will remove all sample groups without impacting tasks.`,
                yesButtonText: "Delete Instance",
                noButtonText: "Cancel",
                extraButton: true,
                extraButtonText: "Delete All",
            });
        } catch {
            // cancel clicked
            return;
        }
    }

    public canRemoveAnySampleGroup() {
        const allSampleGroupsHaveEndStateTask = this.sampleGroups.every(sg => hasEndStateTasks(sg));

        if (allSampleGroupsHaveEndStateTask) {
            return false;
        }

        for (const sg of this.sampleGroups) {
            if (!hasAssociatedSamples(sg)) {
                return true;
            }
        }

        return false;
    }
    
    public async removeAllEligibleSampleGroups() {
        const result = await this.handleRemoveAllEligibleSampleGroupsModal();
        if (softCompare(result, "yes")) {
            const sampleGroupsToRemove = [];
            for (const sg of this.sampleGroups) {
                if (hasAssociatedSamples(sg) || hasEndStateTasks(sg)) {
                    continue;
                }

                sampleGroupsToRemove.push(sg);
            }

            if (!sampleGroupsToRemove.length) {
                return;
            }

            this.jobPharmaDataAccessService.removeSampleGroup(sampleGroupsToRemove);
            this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
            this.jobPharmaDetailService.tabRefresh('tasks', 'list');
        }
    }

    private async handleRemoveAllEligibleSampleGroupsModal(): Promise<string> {
        try {
            const message = "You are about to delete all eligible sample groups. Do you want to proceed?";
            const title = "Delete Sample Groups";
            return await this.confirmService.confirmDelete(title, message);
        } catch {
            // cancel clicked
            return;
        }
    }

    /**
     * 
     * 
     * Remove a SampleGroup from the Task
     * 
     * Prompts to delete all samples and related task instances that are chiildren of the sample group being removed;
     * A save occurs to prevent a foreign key constraint error as the order of requests are not guaranteed and
     * the sample group deletion request can reach the backend before the sample records have been changed
     */
    async removeSampleGroup(sampleGroup: SampleGroupExtended): Promise<any> {
        await this.handleEditableSampleGroupLazyLoad([sampleGroup.C_SampleGroup_key]);

        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            if (hasEndStateTasks(sampleGroup)) {
                return;
            }
            const samplesWithAssociationsDeleted = await this.jobPharmaDetailService.handleSampleAssociationDeletes([sampleGroup]);
            if (!isEmpty(samplesWithAssociationsDeleted)) {
                if (this.sampleGroupsChangeLookup[sampleGroup.C_SampleGroup_key].pendingChanges) {
                    delete this.sampleGroupsChangeLookup[sampleGroup.C_SampleGroup_key].pendingChanges;
                }
            }
        }

        if (sampleGroup.Sample?.length > 0) {
            return;
        }

        this.jobPharmaDataAccessService.removeSampleGroup([sampleGroup]);
        this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
        this.jobPharmaDetailService.tabRefresh('tasks', 'list');
    }

    public resetSampleGroupChanges() {
        for (const sg of this.sampleGroups) {
            delete this.sampleGroupsChangeLookup[sg.C_SampleGroup_key].pendingChanges;
        }
    }

    public getSelected(): SampleGroupExtended[] {
        return this.sampleGroups.filter(sg => sg.isSelected);
    }

    public clearSelected(): void {
        this.sampleGroups.forEach(sg => sg.isSelected = false);
        this.allSelected = false;
    }

    public onSelect(): void {
        this.allSelected = this.sampleGroups.every(sg => sg.isSelected);
    }

    public onSelectAll(): void {
        this.sampleGroups.forEach(sg => {
            if (!this.isLocked(sg) && !hasAssociatedSamples(sg)) {
                sg.isSelected = this.allSelected;
            }
        });
    }

    public async onExpand(sampleGroup: SampleGroupExtended): Promise<void> {
        if (sampleGroup.numSources !== sampleGroup.SampleGroupSourceMaterial.length) {
            await this.loadSampleGroupSourceMaterial(sampleGroup.C_SampleGroup_key);
        }

        sampleGroup.expanded = !sampleGroup.expanded;
        this.allExpanded = this.sampleGroups.every(sg => sg.expanded);
    }

    public async onBulkExpand(): Promise<void> {
        this.bulkExpanded = !this.bulkExpanded;

        const sampleGroupKeysToLazyLoad = [];
        if (this.bulkExpanded) {
            for (const sg of this.sampleGroups) {
                if (sg.numSources !== sg.SampleGroupSourceMaterial.length) {
                    sampleGroupKeysToLazyLoad.push(sg.C_SampleGroup_key);
                }
            }
        }
        if (sampleGroupKeysToLazyLoad.length) {
            await this.loadSampleGroupSourceMaterials(sampleGroupKeysToLazyLoad);
        }

        this.sampleGroups.forEach(sg => sg.expanded = this.bulkExpanded);
    }

    /**
     * Records sample group changes only if the sample group has associated samples.
     */
    public recordSampleGroupChange<K extends keyof (SampleGroupChange | SampleGroup)>(event: SampleGroupChange[K], field: K, sampleGroup: SampleGroupExtended) {
        if (!this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            return;
        }

        if (!hasAssociatedSamples(sampleGroup)) {
            return;
        }

        const sampleGroupLookup = this.sampleGroupsChangeLookup[sampleGroup.C_SampleGroup_key];
        /**
         * If the changed value is the same as it was originally, 
         * delete from pending changes as there are no new changes.
         */ 
        if (softCompare(sampleGroupLookup.originalValues[field], event)) {
            if (!softCompare(sampleGroupLookup.pendingChanges?.[field], null)) {
                delete sampleGroupLookup.pendingChanges[field];
            }
            /**
             * If there is at most 1 value in pending changes, delete
             * the entire pending change record as that value is just
             * a reference to the sample group.
             */
            if (sampleGroupLookup.pendingChanges) {
                if (Object.values(sampleGroupLookup.pendingChanges).length <= 1) {
                    delete sampleGroupLookup.pendingChanges;
                }
            }
            return;
        }

        if (!sampleGroupLookup.pendingChanges) {
            sampleGroupLookup.pendingChanges = {
                sampleGroup
            };
        }

        sampleGroupLookup.pendingChanges[field] = event;
    }

    async handlePendingSampleGroupChanges(token: SaveCancellationToken) {
        try {
            this.setLoading(true);
            const sampleGroupKeys = Object.keys(this.sampleGroupsChangeLookup)
                .map(Number)
                .filter(key => this.sampleGroupsChangeLookup[key]?.pendingChanges);
            if (sampleGroupKeys.length === 0) {
                return;
            }

            const pendingSampleGroupChangesMap: SampleGroupChangesMap = {};
            for (const key of sampleGroupKeys) {
                pendingSampleGroupChangesMap[key] = this.sampleGroupsChangeLookup[key].pendingChanges;
            }

            await this.handleEditableSampleGroupLazyLoad(sampleGroupKeys);
            const isSuccess = await this.jobPharmaDetailService.handlePendingSampleGroupChanges(pendingSampleGroupChangesMap);
            if (!isSuccess) {
                token.cancel();
            }
        } catch(err) {
            token.cancel();
            this.loggingService.logError(ERROR_HANDLING_SAMPLE_GROUP_CHANGES, err, this.LOG_TAG, true);
        }
        finally {
            this.setLoading(false);
        }
    }

    private handleEditableSampleGroupLazyLoad(sampleGroupKeys: number[]) {
        const sampleGroupKeysToLazyLoadSamples = [];
        const sampleGroupKeysToLazyLoadSampleGroupSourceMaterials = [];

        for (const key of sampleGroupKeys) {
            const sampleGroup = this.sampleGroupsChangeLookup[key].sampleGroup;
            if (sampleGroup.Sample?.length !== sampleGroup.SamplesCreatedCount) {
                sampleGroupKeysToLazyLoadSamples.push(sampleGroup.C_SampleGroup_key);
            }
    
            if (sampleGroup.SampleGroupSourceMaterial?.length !== sampleGroup.numSources) {
                sampleGroupKeysToLazyLoadSampleGroupSourceMaterials.push(sampleGroup.C_SampleGroup_key);
            }
        }

        const lazyLoadingPromises: Promise<any>[] = [];
        if (sampleGroupKeysToLazyLoadSamples.length) {
            lazyLoadingPromises.push(this.jobPharmaDataAccessService.getSamplesCreatedFromSampleGroups(sampleGroupKeysToLazyLoadSamples));
        }
        if (sampleGroupKeysToLazyLoadSampleGroupSourceMaterials.length) {
            lazyLoadingPromises.push(this.jobPharmaDataAccessService.getSampleGroupSourceMaterials(sampleGroupKeysToLazyLoadSampleGroupSourceMaterials));
        }

        return Promise.all(lazyLoadingPromises);
    }


    public isLocked(sampleGroup: SampleGroupExtended) {
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            return hasEndStateTasks(sampleGroup);
        }

        return hasAssociatedSamples(sampleGroup);
    }

    // BULK OPERATIONS
    updateBulkNumSamples() {
        this.updateBulkValue('NumSamples', this.bulkNumSamples);
    }
    updateBulkPreservationMethodKey() {
        this.updateBulkValue('C_PreservationMethod_key', this.bulkPreservationMethodKey);
    }
    updateBulkSampleStatusKey() {
        this.updateBulkValue('C_SampleStatus_key', this.bulkSampleStatusKey);
    }
    updateBulkSampleTypeKey() {
        this.updateBulkValue('C_SampleType_key', this.bulkSampleTypeKey);
    }
    updateBulkContainerTypeKey() {
        this.updateBulkValue('C_ContainerType_key', this.bulkContainerTypeKey);
    }
    updateBulkDateHarvest() {
        const msg = dateControlValidator(this._component.harvestDateControls);
        if (msg) {
            this.loggingService.logError(msg, null, '', true);
            return;
        }
        this.updateBulkValue('DateHarvest', this.bulkDateHarvest);
    }
    updateBulkDateExpiration() {
        const msg = dateControlValidator(this._component.expirationDateControls);
        if (msg) {
            this.loggingService.logError(msg, null, '', true);
            return;
        }
        this.updateBulkValue('DateExpiration', this.bulkDateExpiration);
    }
    updateBulkTimePoint() {
        this.updateBulkValue('TimePoint', this.bulkTimePoint);
        this.updateBulkValue('C_TimeUnit_key', this.bulkTimeUnitKey);
    }
    updateBulkSampleSubtypeKey() {
        this.updateBulkValue('C_SampleSubtype_key', this.bulkSampleSubtypeKey);
    }
    updateBulkSampleProcessingMethodKey() {
        this.updateBulkValue('C_SampleProcessingMethod_key', this.bulkSampleProcessingMethodKey);
    }
    updateBulkSampleAnalysisMethodKey() {
        this.updateBulkValue('C_SampleAnalysisMethod_key', this.bulkSampleAnalysisMethodKey);
    }
    updateBulkSendTo() {
        this.updateBulkValue('SendTo', this.bulkSendTo);
    }
    updateBulkSpecialInstructions() {
        this.updateBulkValue('SpecialInstructions', this.bulkSpecialInstructions);
    }
    updateBulkValue<K extends keyof (SampleGroupChange | SampleGroup)>(key: K, value: SampleGroup[K]) {
        if (this._component.readonly) {
            return;
        }

        const sampleGroups = this.sampleGroups ?? [];
        for (const sg of sampleGroups) {
            if (!hasAssociatedSamples(sg) || this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
                if (hasEndStateTasks(sg)) {
                    continue;
                }

                sg[key] = value;

                if (hasAssociatedSamples(sg)) {
                    this.recordSampleGroupChange(value, key, sg);
                }
            }
        }
    }

    // VOCAB FORMATTERS
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }
    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }
    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
    preservationMethodKeyFormatter = (value: any) => {
        return value.C_PreservationMethod_key;
    }
    preservationMethodFormatter = (value: any) => {
        return value.PreservationMethod;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    timeUnitKeyFormatter = (value: any) => {
        return value.C_TimeUnit_key;
    }
    timeUnitFormatter = (value: any) => {
        return value.TimeUnit;
    }
    sampleSubtypeKeyFormatter = (value: any) => {
        return value.C_SampleSubtype_key;
    }
    sampleSubtypeFormatter = (value: any) => {
        return value.SampleSubtype;
    }
    sampleProcessingMethodKeyFormatter = (value: any) => {
        return value.C_SampleProcessingMethod_key;
    }
    sampleProcessingMethodFormatter = (value: any) => {
        return value.SampleProcessingMethod;
    }
    sampleAnalysisMethodKeyFormatter = (value: any) => {
        return value.C_SampleAnalysisMethod_key;
    }
    sampleAnalysisMethodFormatter = (value: any) => {
        return value.SampleAnalysisMethod;
    }
}