/**
 * 
 * Creates a lookup map for the provided list of items based on a unique key value.
 * 
 * @example```
 *  const lookup = createLookup([
 *      { id: 123, name: 'kevin' },
 *      { id: 234, name: 'smith' },
 *      { id: 345, name: 'james' },
 *  ], 'id'); 
 * 
 *  lookup.get(123); // { id: 123, name: 'kevin' }
 *  lookup.get(234); // { id: 234, name: 'smith' }
 *  lookup.get(345); // { id: 345, name: 'james' }
 * ```
 */
export const createLookup = <T, K extends keyof T>(items: T[], keyPath: K): Map<T[K], T> => {
    const lookup = new Map<T[K], T>();
    for (const item of items) {
        if (!item || typeof item !== 'object') {
            throw new Error("Items must be an array of objects!");
        }

        const value = item[keyPath];
        if (!value) {
            throw new Error(`Key '${String(keyPath)}' not found!`);
        }

        if (lookup.has(value)) {
            throw new Error(`Duplicate value for key: '${String(keyPath)}'!`);
        }

        lookup.set(value, item);
    }

    return lookup;
}